* {
  padding: 0;
  margin: 0;
}

.fadeInUp > .measure-line > p {
  animation-name: fadeInUp;
}
.fadeInDown > .measure-line > p {
  animation-name: fadeInDown;
}
.fadeInLeft > .measure-line > p {
  animation-name: fadeInLeft;
}
.fadeInRight > .measure-line > p {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fadeInDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
