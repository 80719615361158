@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-SemiBold.eot");
  src: local("Metronic Pro SemiBold"), local("MetronicPro-SemiBold"), url("../assets/fonts/MetronicPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-SemiBold.woff2") format("woff2"), url("../assets/fonts/MetronicPro-SemiBold.woff") format("woff"), url("../assets/fonts/MetronicPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-LightItalic.eot");
  src: local("Metronic Pro Light Italic"), local("MetronicPro-LightItalic"), url("../assets/fonts/MetronicPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-LightItalic.woff2") format("woff2"), url("../assets/fonts/MetronicPro-LightItalic.woff") format("woff"), url("../assets/fonts/MetronicPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-Bold.eot");
  src: local("Metronic Pro Bold"), local("MetronicPro-Bold"), url("../assets/fonts/MetronicPro-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-Bold.woff2") format("woff2"), url("../assets/fonts/MetronicPro-Bold.woff") format("woff"), url("../assets/fonts/MetronicPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-Black.eot");
  src: local("Metronic Pro Black"), local("MetronicPro-Black"), url("../assets/fonts/MetronicPro-Black.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-Black.woff2") format("woff2"), url("../assets/fonts/MetronicPro-Black.woff") format("woff"), url("../assets/fonts/MetronicPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-Light.eot");
  src: local("Metronic Pro Light"), local("MetronicPro-Light"), url("../assets/fonts/MetronicPro-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-Light.woff2") format("woff2"), url("../assets/fonts/MetronicPro-Light.woff") format("woff"), url("../assets/fonts/MetronicPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-SemiBoldItalic.eot");
  src: local("Metronic Pro SemiBold Italic"), local("MetronicPro-SemiBoldItalic"), url("../assets/fonts/MetronicPro-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-SemiBoldItalic.woff2") format("woff2"), url("../assets/fonts/MetronicPro-SemiBoldItalic.woff") format("woff"), url("../assets/fonts/MetronicPro-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-BlackItalic.eot");
  src: local("Metronic Pro Black Italic"), local("MetronicPro-BlackItalic"), url("../assets/fonts/MetronicPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-BlackItalic.woff2") format("woff2"), url("../assets/fonts/MetronicPro-BlackItalic.woff") format("woff"), url("../assets/fonts/MetronicPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-BoldItalic.eot");
  src: local("Metronic Pro Bold Italic"), local("MetronicPro-BoldItalic"), url("../assets/fonts/MetronicPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-BoldItalic.woff2") format("woff2"), url("../assets/fonts/MetronicPro-BoldItalic.woff") format("woff"), url("../assets/fonts/MetronicPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro-Italic.eot");
  src: local("Metronic Pro Italic"), local("MetronicPro-Italic"), url("../assets/fonts/MetronicPro-Italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro-Italic.woff2") format("woff2"), url("../assets/fonts/MetronicPro-Italic.woff") format("woff"), url("../assets/fonts/MetronicPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Metronic Pro";
  src: url("../assets/fonts/MetronicPro.eot");
  src: local("Metronic Pro"), local("MetronicPro"), url("../assets/fonts/MetronicPro.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/MetronicPro.woff2") format("woff2"), url("../assets/fonts/MetronicPro.woff") format("woff"), url("../assets/fonts/MetronicPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-Bold.eot");
  src: local("Gotham Pro Bold"), local("GothamPro-Bold"), url("../assets/fonts/GothamPro-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-Bold.woff2") format("woff2"), url("../assets/fonts/GothamPro-Bold.woff") format("woff"), url("../assets/fonts/GothamPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-Light.eot");
  src: local("Gotham Pro Light"), local("GothamPro-Light"), url("../assets/fonts/GothamPro-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-Light.woff2") format("woff2"), url("../assets/fonts/GothamPro-Light.woff") format("woff"), url("../assets/fonts/GothamPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-Black.eot");
  src: local("Gotham Pro Black"), local("GothamPro-Black"), url("../assets/fonts/GothamPro-Black.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-Black.woff2") format("woff2"), url("../assets/fonts/GothamPro-Black.woff") format("woff"), url("../assets/fonts/GothamPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-Italic.eot");
  src: local("Gotham Pro Italic"), local("GothamPro-Italic"), url("../assets/fonts/GothamPro-Italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-Italic.woff2") format("woff2"), url("../assets/fonts/GothamPro-Italic.woff") format("woff"), url("../assets/fonts/GothamPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-Medium.eot");
  src: local("Gotham Pro Medium"), local("GothamPro-Medium"), url("../assets/fonts/GothamPro-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-Medium.woff2") format("woff2"), url("../assets/fonts/GothamPro-Medium.woff") format("woff"), url("../assets/fonts/GothamPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-BoldItalic.eot");
  src: local("Gotham Pro Bold Italic"), local("GothamPro-BoldItalic"), url("../assets/fonts/GothamPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-BoldItalic.woff2") format("woff2"), url("../assets/fonts/GothamPro-BoldItalic.woff") format("woff"), url("../assets/fonts/GothamPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro.eot");
  src: local("Gotham Pro"), local("GothamPro"), url("../assets/fonts/GothamPro.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro.woff2") format("woff2"), url("../assets/fonts/GothamPro.woff") format("woff"), url("../assets/fonts/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-MediumItalic.eot");
  src: local("Gotham Pro Medium Italic"), local("GothamPro-MediumItalic"), url("../assets/fonts/GothamPro-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-MediumItalic.woff2") format("woff2"), url("../assets/fonts/GothamPro-MediumItalic.woff") format("woff"), url("../assets/fonts/GothamPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-BlackItalic.eot");
  src: local("Gotham Pro Black Italic"), local("GothamPro-BlackItalic"), url("../assets/fonts/GothamPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-BlackItalic.woff2") format("woff2"), url("../assets/fonts/GothamPro-BlackItalic.woff") format("woff"), url("../assets/fonts/GothamPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothamPro-LightItalic.eot");
  src: local("Gotham Pro Light Italic"), local("GothamPro-LightItalic"), url("../assets/fonts/GothamPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/GothamPro-LightItalic.woff2") format("woff2"), url("../assets/fonts/GothamPro-LightItalic.woff") format("woff"), url("../assets/fonts/GothamPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  color: #ffffff;
  font-size: 16px;
  font-family: "Gotham Pro", "Metronic Pro";
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media (min-width: 1500px) {
  body {
    font-size: 18px;
  }
}
body::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar-track {
  background: transparent;
  opacity: 0;
}
body::-webkit-scrollbar-thumb {
  background: red;
}
body::-webkit-scrollbar-thumb:hover {
  background: blue;
}

.text-box {
  width: 100%;
}

.container {
  margin: auto;
  width: 83.3333333vw;
}
@media (min-width: 1700px) {
  .container {
    width: 1416px;
  }
}
@media (max-width: 1100px) {
  .container {
    width: 85vw;
  }
}
@media (max-width: 850px) {
  .container {
    width: 80.5882354vw;
  }
}
@media (max-width: 650px) {
  .container {
    max-width: 520px;
  }
}
@media (max-width: 500px) {
  .container {
    width: 95vw;
    max-width: 91vw;
  }
}

button {
  border: 0px;
  outline: 0px;
  font-family: inherit;
  font-size: inherit;
}

.button {
  background-color: #ffffff;
  height: 4.16666666vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1f1f1f;
  width: 100%;
  font-weight: 300;
  cursor: pointer;
}
@media (min-width: 1700px) {
  .button {
    height: 71px;
  }
}
@media (max-width: 1100px) {
  .button {
    height: 60px;
  }
}

@-webkit-keyframes openImage {
  from {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@keyframes openImage {
  from {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
@-webkit-keyframes closeImage {
  from {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
}
@keyframes closeImage {
  from {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
}
.open-image {
  -webkit-animation-name: openImage;
          animation-name: openImage;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.close-image {
  -webkit-animation-name: closeImage;
          animation-name: closeImage;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.title-univerzal {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 18.0555556vw;
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (min-width: 1700px) {
  .title-univerzal {
    grid-template-rows: 307px;
  }
}
@media (max-width: 1100px) {
  .title-univerzal {
    grid-template-rows: 210px;
    grid-template-columns: 2fr 1fr;
  }
}
@media (max-width: 850px) {
  .title-univerzal {
    grid-template-rows: 200px;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 650px) {
  .title-univerzal {
    grid-template-rows: 140px;
  }
}
.title-univerzal h1 {
  padding-left: 8.33333333vw;
  font-size: 4.16666666vw;
  font-weight: 400;
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  display: flex;
  align-items: center;
}
@media (min-width: 1700px) {
  .title-univerzal h1 {
    padding-left: 142px;
    font-size: 71px;
  }
}
@media (max-width: 1100px) {
  .title-univerzal h1 {
    padding-left: 80px;
    font-size: 54px;
  }
}
@media (max-width: 850px) {
  .title-univerzal h1 {
    padding-left: 70px;
    font-size: 50px;
    border-top: 0.5px solid rgba(31, 31, 31, 0.1);
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
@media (max-width: 650px) {
  .title-univerzal h1 {
    font-size: 39px;
    padding-left: 24px;
  }
}
.title-univerzal .empty-box {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (max-width: 850px) {
  .title-univerzal .empty-box {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.loader {
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 1s ease;
  z-index: 1000000000000;
}
.loader img {
  transition: 1s;
  -webkit-clip-path: polygon(50% 0, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 50% 0%);
          clip-path: polygon(50% 0, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 50% 0%);
  padding: 0px;
  -webkit-animation-name: loader;
          animation-name: loader;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
@-webkit-keyframes loader {
  0% {
    -webkit-clip-path: polygon(50% 0, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 50% 0%);
            clip-path: polygon(50% 0, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 50% 0%);
  }
  33% {
    -webkit-clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 24% 84.5%, 24% 84.5%, 24% 84.5%, 17% 100%, 0 100%);
            clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 24% 84.5%, 24% 84.5%, 24% 84.5%, 17% 100%, 0 100%);
  }
  66% {
    -webkit-clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 76% 84.5%, 76% 84.5%, 92% 84.5%, 100% 100%, 0 100%);
            clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 76% 84.5%, 76% 84.5%, 92% 84.5%, 100% 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 76% 84.5%, 63% 58%, 70.5% 42%, 100% 100%, 0 100%);
            clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 76% 84.5%, 63% 58%, 70.5% 42%, 100% 100%, 0 100%);
  }
}
@keyframes loader {
  0% {
    -webkit-clip-path: polygon(50% 0, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 50% 0%);
            clip-path: polygon(50% 0, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 58% 16%, 50% 0%);
  }
  33% {
    -webkit-clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 24% 84.5%, 24% 84.5%, 24% 84.5%, 17% 100%, 0 100%);
            clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 24% 84.5%, 24% 84.5%, 24% 84.5%, 17% 100%, 0 100%);
  }
  66% {
    -webkit-clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 76% 84.5%, 76% 84.5%, 92% 84.5%, 100% 100%, 0 100%);
            clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 76% 84.5%, 76% 84.5%, 92% 84.5%, 100% 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 76% 84.5%, 63% 58%, 70.5% 42%, 100% 100%, 0 100%);
            clip-path: polygon(50% 0, 58% 16%, 24% 84.5%, 76% 84.5%, 63% 58%, 70.5% 42%, 100% 100%, 0 100%);
  }
}

nav {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  width: 100%;
  grid-template-rows: 16.6666667vw;
}
@media (min-width: 1700px) {
  nav {
    grid-template-rows: 283px;
  }
}
@media (max-width: 1100px) {
  nav {
    grid-template-rows: 240px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 850px) {
  nav {
    grid-template-columns: -webkit-max-content 1fr 50%;
    grid-template-columns: max-content 1fr 50%;
  }
}
@media (max-width: 650px) {
  nav {
    grid-template-columns: 85px 1fr;
    grid-template-rows: 75px;
  }
}
nav .language-box {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
@media (max-width: 850px) {
  nav .language-box {
    padding-bottom: 60px;
    border-right: 0px;
    border-bottom: 0px;
  }
}
@media (max-width: 650px) {
  nav .language-box {
    display: none;
  }
}
nav .language-box .language-button {
  width: 4.16666666vw;
  height: 4.16666666vw;
  border-right: 0.9px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
}
@media (min-width: 1700px) {
  nav .language-box .language-button {
    height: 71px;
    width: 71px;
  }
}
@media (max-width: 1100px) {
  nav .language-box .language-button {
    height: 60px;
    width: 60px;
  }
}
nav .language-box .active {
  color: #1f1f1f;
  background-color: #ffffff;
}
nav .language-box .language-border-bottom {
  border-bottom: 0.9px solid rgba(255, 255, 255, 0.1);
}
nav .logo-box {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 850px) {
  nav .logo-box {
    margin-bottom: 60px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 650px) {
  nav .logo-box {
    margin-bottom: 0px;
    border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
nav .logo-box img {
  height: 5.13888889vw;
  width: auto;
}
@media (min-width: 1700px) {
  nav .logo-box img {
    height: 83px;
    width: auto;
  }
}
@media (max-width: 1100px) {
  nav .logo-box img {
    height: 63px;
  }
}
@media (max-width: 650px) {
  nav .logo-box img {
    height: 30px;
  }
}
nav .wrapper-box {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 850px) {
  nav .wrapper-box {
    border-bottom: 0px;
    border-left: 0px;
  }
}
nav .wrapper-box .menu-box {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  height: 12.5vw;
  display: flex;
  align-items: flex-end;
  padding: 1.38888889vw;
}
@media (min-width: 1700px) {
  nav .wrapper-box .menu-box {
    height: 212px;
    padding: 24px;
  }
}
@media (max-width: 1100px) {
  nav .wrapper-box .menu-box {
    height: 180px;
    padding: 25px;
  }
}
@media (max-width: 850px) {
  nav .wrapper-box .menu-box {
    border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 650px) {
  nav .wrapper-box .menu-box {
    height: 100%;
    justify-content: flex-end;
  }
}
nav .wrapper-box .menu-box p {
  cursor: pointer;
}

.blackNav {
  color: #1f1f1f;
}
.blackNav .language-box {
  border-color: rgba(31, 31, 31, 0.1);
}
.blackNav .language-box .language-button {
  border-color: rgba(31, 31, 31, 0.1);
}
.blackNav .language-box .active {
  background-color: #1f1f1f;
  color: #ffffff;
}
.blackNav .language-box .language-border-bottom {
  border-color: rgba(31, 31, 31, 0.1);
}
.blackNav .logo-box {
  border-color: rgba(31, 31, 31, 0.1);
}
.blackNav .wrapper-box {
  border-color: rgba(31, 31, 31, 0.1);
}
.blackNav .wrapper-box .menu-box {
  border-color: rgba(31, 31, 31, 0.1);
}

.navigation-menu-open {
  transform: translateY(0%) !important;
  pointer-events: inherit !important;
}

.navigation-menu {
  background-color: #1f1f1f;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  overflow-y: scroll;
  z-index: 100;
  transform: translateY(-100%);
  transition: 1s ease;
  pointer-events: none;
}
.navigation-menu::-webkit-scrollbar {
  display: none;
}
@media (max-width: 650px) {
  .navigation-menu nav {
    grid-template-columns: 75px 1fr;
  }
  .navigation-menu nav .language-box {
    display: block !important;
    height: 100%;
  }
  .navigation-menu nav .language-box .language-button {
    height: 75px;
    width: 75px;
    background: #1f1f1f;
    z-index: 1000;
    position: relative;
    opacity: 1;
    transition: 0.5s ease;
  }
  .navigation-menu nav .language-box .language-button:nth-child(2) {
    transform: translateY(0%);
  }
  .navigation-menu nav .language-box .language-button:nth-child(3) {
    transform: translateY(0%);
  }
  .navigation-menu nav .language-box .not-show {
    opacity: 0;
  }
  .navigation-menu nav .language-box .not-show:nth-child(2) {
    transform: translateY(-100%);
  }
  .navigation-menu nav .language-box .not-show:nth-child(3) {
    transform: translateY(-200%);
  }
  .navigation-menu nav .language-box .active {
    background-color: #ffffff;
    opacity: 1 !important;
  }
  .navigation-menu .logo-box {
    display: none;
  }
}
.navigation-menu .container {
  border: 0px;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
  display: grid;
  grid-template-rows: 16.6666667vw 1fr;
}
@media (min-width: 1700px) {
  .navigation-menu .container {
    grid-template-rows: 283px 1fr;
  }
}
@media (max-width: 1100px) {
  .navigation-menu .container {
    grid-template-rows: 240px 1fr;
  }
}
@media (max-width: 650px) {
  .navigation-menu .container {
    grid-template-rows: 75px 1fr;
  }
}
@media (max-height: 660px) {
  .navigation-menu .container .hide {
    display: none;
  }
}
.navigation-menu .nav-body {
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 100%;
}
@media (max-width: 1100px) {
  .navigation-menu .nav-body {
    grid-template-columns: 2fr 1fr;
  }
}
@media (max-width: 850px) {
  .navigation-menu .nav-body {
    grid-template-columns: 1fr 1fr;
    border-top: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 650px) {
  .navigation-menu .nav-body {
    grid-template-columns: 1fr;
    border-top: 0px;
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
.navigation-menu .nav-body .left {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navigation-menu .nav-body .left .items-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  padding-left: 60px;
}
@media (max-width: 850px) {
  .navigation-menu .nav-body .left .items-list {
    padding-top: 30px;
    padding-left: 30px;
    flex-direction: column;
  }
}
@media (max-width: 650px) {
  .navigation-menu .nav-body .left .items-list {
    padding-top: 35px;
    padding-bottom: 15px;
    padding-left: 20px;
    flex-direction: column;
  }
}
.navigation-menu .nav-body .left .items-list p {
  font-size: 3.125vw;
  line-height: 2.5vw;
}
@media (min-width: 1700px) {
  .navigation-menu .nav-body .left .items-list p {
    font-size: 53px;
    list-style: 43px;
  }
}
@media (max-width: 1100px) {
  .navigation-menu .nav-body .left .items-list p {
    font-size: 45px;
    line-height: 50px;
  }
}
@media (max-width: 850px) {
  .navigation-menu .nav-body .left .items-list p {
    font-size: 39px;
    line-height: 35px;
  }
}
.navigation-menu .nav-body .left .items-list a {
  margin-right: 70px;
  margin-bottom: 70px;
}
@media (max-width: 1100px) {
  .navigation-menu .nav-body .left .items-list a {
    margin-bottom: 45px;
    margin-right: 60px;
  }
}
@media (max-width: 940px) {
  .navigation-menu .nav-body .left .items-list a {
    margin-right: 35px;
  }
}
@media (max-width: 850px) {
  .navigation-menu .nav-body .left .items-list a {
    margin-right: 0px;
  }
}
@media (max-width: 850px) {
  .navigation-menu .nav-body .left .items-list a {
    margin-bottom: 25px;
  }
}
.navigation-menu .nav-body .left .cont-info {
  font-size: 1.25vw;
  font-weight: 600;
  display: flex;
  margin-left: 60px;
  margin-bottom: 60px;
}
.navigation-menu .nav-body .left .cont-info .mob {
  display: none;
}
@media (min-width: 1700px) {
  .navigation-menu .nav-body .left .cont-info {
    font-size: 21px;
  }
}
@media (max-width: 1100px) {
  .navigation-menu .nav-body .left .cont-info {
    font-size: 16px;
    margin-bottom: 50px;
  }
}
@media (max-width: 850px) {
  .navigation-menu .nav-body .left .cont-info {
    margin-bottom: 27px;
    margin-left: 30px;
    flex-direction: column;
    opacity: 0.4;
  }
}
@media (max-width: 650px) {
  .navigation-menu .nav-body .left .cont-info {
    height: 100%;
    opacity: 1;
    margin-left: 0px;
  }
  .navigation-menu .nav-body .left .cont-info .desktop {
    display: none;
  }
  .navigation-menu .nav-body .left .cont-info .mob {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .navigation-menu .nav-body .left .cont-info .mob .opacity {
    align-self: center;
    opacity: 0.4;
  }
}
.navigation-menu .nav-body .left .cont-info p:nth-child(1) {
  margin-right: 4.86111111vw;
}
@media (min-width: 1700px) {
  .navigation-menu .nav-body .left .cont-info p:nth-child(1) {
    margin-right: 83px;
  }
}
.navigation-menu .nav-body .right {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 650px) {
  .navigation-menu .nav-body .right {
    display: none;
  }
}
.navigation-menu .nav-body .right .text .title {
  font-size: 1.94444444vw;
  padding-bottom: 1.73611111vw;
  padding-left: 1.73611111vw;
  padding-right: 3.125vw;
  line-height: 2.56944444vw;
}
@media (min-width: 1700px) {
  .navigation-menu .nav-body .right .text .title {
    font-size: 33px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 53px;
    line-height: 44px;
  }
}
@media (max-width: 1100px) {
  .navigation-menu .nav-body .right .text .title {
    font-size: 28px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 60px;
    line-height: 37px;
  }
}
@media (max-width: 910px) {
  .navigation-menu .nav-body .right .text .title {
    font-size: 22px;
    line-height: 32px;
    padding-right: 15px;
  }
}
.navigation-menu .nav-body .right .contact-form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 4.16666667vw);
}
@media (min-width: 1700px) {
  .navigation-menu .nav-body .right .contact-form {
    grid-template-rows: repeat(4, 71px);
  }
}
@media (max-width: 1100px) {
  .navigation-menu .nav-body .right .contact-form {
    grid-template-rows: repeat(4, 60px);
  }
}
.navigation-menu .nav-body .right .contact-form input {
  background-color: transparent;
  color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 0px;
  outline: 0px;
  opacity: 0.4;
  padding-left: 1.73611111vw;
}
@media (min-width: 1700px) {
  .navigation-menu .nav-body .right .contact-form input {
    padding-left: 30px;
  }
}
@media (max-width: 1100px) {
  .navigation-menu .nav-body .right .contact-form input {
    padding-left: 25px;
  }
}
.navigation-menu .nav-body .right .contact-form .name,
.navigation-menu .nav-body .right .contact-form .email {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
}
.navigation-menu .nav-body .right .contact-form .name {
  border-top: 0.5px solid rgba(255, 255, 255, 0.1);
}
.navigation-menu .nav-body .right .contact-form .empty-box {
  display: none;
}
.navigation-menu .nav-body .right .contact-form .eb2 {
  display: none;
}
.navigation-menu .nav-body .right .contact-form .button-section {
  width: 100%;
  cursor: pointer;
}
.navigation-menu .nav-body .right .contact-form .button-section .button {
  height: 100% !important;
}

.nav-item-wrapper {
  overflow: hidden;
}
.nav-item-wrapper .hidden-item {
  transform: translateY(100%);
  transition: 0.5s ease;
  transition-delay: 0.4s;
}
.nav-item-wrapper .item {
  line-height: 120% !important;
}
.nav-item-wrapper .item1 {
  transition-delay: 0.5s;
}
.nav-item-wrapper .item2 {
  transition-delay: 0.7s;
}
.nav-item-wrapper .item3 {
  transition-delay: 0.9s;
}
.nav-item-wrapper .item4 {
  transition-delay: 1.1s;
}
.nav-item-wrapper .item5 {
  transition-delay: 1.3s;
}
.nav-item-wrapper .show {
  transform: translateY(0%);
}

.home-page {
  background-color: #1f1f1f;
}

.home-hero {
  background-image: url("../assets/image/home-bg.svg");
  height: 50vw;
  background-position: center;
  background-size: cover;
  margin-top: -16.6666667vw;
  padding-top: 16.6666667vw;
}
@media (min-width: 1700px) {
  .home-hero {
    margin-top: -283px;
    padding-top: 283px;
    height: 850px;
  }
}
@media (max-width: 1100px) {
  .home-hero {
    margin-top: -240px;
    padding-top: 240px;
    height: 100%;
    min-height: 670px;
  }
}
@media (max-width: 650px) {
  .home-hero {
    margin-top: -75px;
    padding-top: 75px;
    height: 100%;
    max-height: 800px;
  }
}
.home-hero .middle-section {
  height: 16.6666667vw;
  display: grid;
  grid-template-columns: 3fr 1fr;
}
@media (min-width: 1700px) {
  .home-hero .middle-section {
    height: 283px;
  }
}
@media (max-width: 1100px) {
  .home-hero .middle-section {
    height: 280px;
  }
}
@media (max-width: 1340px) {
  .home-hero .middle-section {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 850px) {
  .home-hero .middle-section {
    border-top: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 650px) {
  .home-hero .middle-section {
    height: 680px;
    grid-template-rows: 1fr 250px 1fr;
  }
}
@media (max-width: 650px) {
  .home-hero .middle-section {
    height: 560px;
    grid-template-rows: 1fr 250px 1fr;
  }
}
.home-hero .middle-section .heading {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.81944444vw;
}
@media (min-width: 1700px) {
  .home-hero .middle-section .heading {
    padding-left: 65px;
  }
}
@media (max-width: 1100px) {
  .home-hero .middle-section .heading {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 1340px) {
  .home-hero .middle-section .heading {
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 650px) {
  .home-hero .middle-section .heading {
    border-top: 0.5px solid rgba(255, 255, 255, 0.1);
    padding-left: 17px;
  }
}
.home-hero .middle-section .heading h1 {
  font-size: 4.86111111vw;
  font-weight: 400;
}
@media (min-width: 1700px) {
  .home-hero .middle-section .heading h1 {
    font-size: 83px;
  }
}
@media (max-width: 1440px) {
  .home-hero .middle-section .heading h1 {
    font-size: 70px;
  }
}
@media (max-width: 1100px) {
  .home-hero .middle-section .heading h1 {
    line-height: 73px;
    margin-bottom: 11px;
  }
}
@media (max-width: 850px) {
  .home-hero .middle-section .heading h1 {
    font-size: 64px;
    line-height: 64px;
    margin-bottom: 14.5px;
  }
}
@media (max-width: 650px) {
  .home-hero .middle-section .heading h1 {
    font-size: 39px;
    line-height: 42px;
    margin-bottom: 8px;
  }
}
.home-hero .middle-section .heading .description {
  font-weight: 300;
}
@media (max-width: 650px) {
  .home-hero .middle-section .heading .description {
    max-width: 260px;
  }
}
.home-hero .middle-section .empty-box2 {
  display: none;
}
@media (max-width: 650px) {
  .home-hero .middle-section .empty-box2 {
    display: block;
    width: 100%;
    height: 100%;
    border-left: 0.5px solid rgba(255, 255, 255, 0.1);
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
.home-hero .middle-section .empty-box {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 1340px) {
  .home-hero .middle-section .empty-box {
    display: none;
  }
}
@media (max-width: 650px) {
  .home-hero .middle-section .empty-box {
    display: inline;
    height: 100%;
    width: 100%;
    border-bottom: 0px;
  }
}
.home-hero .bottom-section {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 16.6666667vw;
}
@media (min-width: 1700px) {
  .home-hero .bottom-section {
    grid-template-rows: 283px;
  }
}
@media (max-width: 1100px) {
  .home-hero .bottom-section {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 190px;
  }
}
@media (max-width: 850px) {
  .home-hero .bottom-section {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 190px;
  }
}
@media (max-width: 650px) {
  .home-hero .bottom-section {
    grid-template-columns: 1fr;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
  }
}
.home-hero .bottom-section .contact-box {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: flex-end;
}
@media (max-width: 850px) {
  .home-hero .bottom-section .contact-box {
    border-right: 0px;
  }
}
.home-hero .bottom-section .empty-box {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 850px) {
  .home-hero .bottom-section .empty-box {
    display: none;
  }
}
.home-hero .bottom-section .project-box {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: flex-end;
}
@media (max-width: 650px) {
  .home-hero .bottom-section .project-box {
    display: none;
  }
}

.home-projects {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  display: grid;
  grid-template-rows: 10.4166667vw -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: 10.4166667vw max-content max-content max-content;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "empty1 empty1 empty2 empty2" "title title box5 box6" "line line box7 box8" "line line box9 box10";
}
@media (min-width: 1700px) {
  .home-projects {
    grid-template-rows: 177px -webkit-max-content -webkit-max-content;
    grid-template-rows: 177px max-content max-content;
  }
}
@media (max-width: 1100px) {
  .home-projects {
    grid-template-rows: 85px -webkit-max-content -webkit-max-content -webkit-max-content 115px;
    grid-template-rows: 85px max-content max-content max-content 115px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "empty1 empty1 empty1" "title title title" "box5 box6 box7" "box8 box9 box10" "empty2 empty2 empty2";
  }
}
@media (max-width: 850px) {
  .home-projects {
    grid-template-rows: 100px -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content 100px;
    grid-template-rows: 100px max-content max-content max-content max-content 100px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "empty1 empty1" "title title" "box5 box6" "box7 box8" "box9 box10" "empty2 empty2";
  }
}
@media (max-width: 650px) {
  .home-projects {
    grid-template-rows: 85px -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content 160px;
    grid-template-rows: 85px max-content max-content max-content max-content max-content max-content max-content 160px;
    grid-template-columns: 1fr;
    grid-template-areas: "empty1" "title" "box5" "box6" "box7" "box8" "box9" "box10" "line";
  }
}
.home-projects img {
  height: 1.875vw;
  width: auto;
}
@media (min-width: 1700px) {
  .home-projects img {
    height: 32px;
  }
}
@media (max-width: 1100px) {
  .home-projects img {
    height: 27px;
  }
}
.home-projects .empty-box {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  grid-area: empty1;
}
@media (max-width: 1100px) {
  .home-projects .empty-box {
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
.home-projects .empty-box2 {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  grid-area: empty2;
}
@media (max-width: 650px) {
  .home-projects .empty-box2 {
    display: none;
  }
}
.home-projects .description {
  line-height: 150%;
}
@media (max-width: 650px) {
  .home-projects .description {
    margin-top: -10px;
  }
}
.home-projects .text {
  padding-left: 3.47222222vw;
  padding-right: 4.86111111vw;
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  grid-area: title;
}
@media (min-width: 1700px) {
  .home-projects .text {
    padding-left: 59px;
    padding-right: 83px;
  }
}
@media (max-width: 1100px) {
  .home-projects .text {
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 75px;
    padding-left: 60px;
  }
}
@media (max-width: 850px) {
  .home-projects .text {
    padding-bottom: 50px;
    padding-left: 40px;
  }
}
@media (max-width: 650px) {
  .home-projects .text {
    padding-bottom: 70px;
    padding-left: 18px;
  }
}
.home-projects .text h2 {
  font-size: 4.16666666vw;
  font-weight: 400;
  width: 150%;
}
@media (min-width: 1700px) {
  .home-projects .text h2 {
    font-size: 68px;
  }
}
@media (max-width: 1100px) {
  .home-projects .text h2 {
    font-size: 54px;
    width: 100%;
  }
}
@media (max-width: 650px) {
  .home-projects .text h2 {
    font-size: 39px;
    line-height: 52px;
    margin-bottom: 10px;
  }
}
.home-projects .text p {
  font-weight: 300;
}
@media (max-width: 1100px) {
  .home-projects .text p {
    width: 480px;
  }
}
@media (max-width: 650px) {
  .home-projects .text p {
    width: 100%;
  }
}
.home-projects .diagonal-line {
  grid-area: line;
  position: relative;
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  width: 100%;
}
@media (max-width: 1100px) {
  .home-projects .diagonal-line {
    display: none;
  }
}
@media (max-width: 650px) {
  .home-projects .diagonal-line {
    display: block;
    height: 160px;
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
.home-projects .diagonal-line svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.home-projects .diagonal-line svg line {
  stroke: rgba(255, 255, 255, 0.1);
  stroke-width: 1;
}
.home-projects .project-box {
  position: relative;
  transition: 250ms;
}
.home-projects .project-box:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.home-projects .project-box .active-img-box {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%) !important;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%) !important;
}
.home-projects .project-box .img-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: 0.5s;
  background-position: center;
  background-size: cover !important;
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
          clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
}
@media (max-width: 650px) {
  .home-projects .project-box .img-box {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}
.home-projects .project-box .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.home-projects .project-box .img-box .opq {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 100;
}
.home-projects .project-box .content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover !important;
  padding: 2.08333333vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 1700px) {
  .home-projects .project-box .content {
    padding: 35px;
  }
}
@media (max-width: 1100px) {
  .home-projects .project-box .content {
    padding: 30px;
  }
}
.home-projects .project-box .content h4 {
  width: 11.4583333vw;
  font-weight: 300;
  z-index: 11;
}
@media (min-width: 1700px) {
  .home-projects .project-box .content h4 {
    width: 195px;
  }
}
@media (max-width: 1100px) {
  .home-projects .project-box .content h4 {
    width: 165px;
  }
}
.home-projects .project-box .content .bottom {
  display: flex;
  justify-content: space-between;
  opacity: 0.7;
  z-index: 11;
}
.home-projects .project-box .content .active {
  opacity: 1;
}
.home-projects .project-box:nth-child(5) {
  grid-area: box5;
}
.home-projects .project-box:nth-child(6) {
  grid-area: box6;
}
.home-projects .project-box:nth-child(7) {
  grid-area: box7;
}
.home-projects .project-box:nth-child(8) {
  grid-area: box8;
}
.home-projects .project-box:nth-child(9) {
  grid-area: box9;
}
.home-projects .project-box:nth-child(10) {
  grid-area: box10;
}
@media (max-width: 650px) {
  .home-projects .project-box:nth-child(5) {
    height: 150px;
    transition: 0.5s ease-in;
  }
  .home-projects .project-box:nth-child(5) .img-box img {
    opacity: 0;
    transition: 0.5s;
    height: 450px;
  }
  .home-projects .project-box:nth-child(5) .img-box .opq {
    opacity: 0;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(5) .bottom a:nth-child(1) {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(6) {
    height: 150px;
    transition: 0.5s ease-in;
  }
  .home-projects .project-box:nth-child(6) .img-box img {
    opacity: 0;
    transition: 0.5s;
    height: 450px;
  }
  .home-projects .project-box:nth-child(6) .img-box .opq {
    opacity: 0;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(6) .bottom a:nth-child(1) {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(7) {
    height: 150px;
    transition: 0.5s ease-in;
  }
  .home-projects .project-box:nth-child(7) .img-box img {
    opacity: 0;
    transition: 0.5s;
    height: 450px;
  }
  .home-projects .project-box:nth-child(7) .img-box .opq {
    opacity: 0;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(7) .bottom a:nth-child(1) {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(8) {
    height: 150px;
    transition: 0.5s ease-in;
  }
  .home-projects .project-box:nth-child(8) .img-box img {
    opacity: 0;
    transition: 0.5s;
    height: 450px;
  }
  .home-projects .project-box:nth-child(8) .img-box .opq {
    opacity: 0;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(8) .bottom a:nth-child(1) {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(9) {
    height: 150px;
    transition: 0.5s ease-in;
  }
  .home-projects .project-box:nth-child(9) .img-box img {
    opacity: 0;
    transition: 0.5s;
    height: 450px;
  }
  .home-projects .project-box:nth-child(9) .img-box .opq {
    opacity: 0;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(9) .bottom a:nth-child(1) {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(10) {
    height: 150px;
    transition: 0.5s ease-in;
  }
  .home-projects .project-box:nth-child(10) .img-box img {
    opacity: 0;
    transition: 0.5s;
    height: 450px;
  }
  .home-projects .project-box:nth-child(10) .img-box .opq {
    opacity: 0;
    transition: 0.5s;
  }
  .home-projects .project-box:nth-child(10) .bottom a:nth-child(1) {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  .home-projects .active-box {
    height: 450px !important;
  }
  .home-projects .active-box .img-box img {
    opacity: 1 !important;
  }
  .home-projects .active-box .img-box .opq {
    opacity: 1 !important;
  }
  .home-projects .active-box .bottom a:nth-child(1) {
    pointer-events: initial;
    opacity: 1 !important;
  }
}
.home-projects .project-box:nth-child(odd) {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
}
.home-projects .project-box:nth-child(even) {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 1100px) {
  .home-projects .project-box:nth-child(5),
.home-projects .project-box:nth-child(6),
.home-projects .project-box:nth-child(7) {
    border-top: 0.5px solid rgba(255, 255, 255, 0.1);
  }
  .home-projects .project-box:nth-child(7) {
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 650px) {
  .home-projects .project-box {
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}

.home-project-slider {
  height: 50vw;
  background-color: #ffffff;
  color: #1f1f1f;
}
@media (min-width: 1700px) {
  .home-project-slider {
    height: 850px;
  }
}
@media (max-width: 1100px) {
  .home-project-slider {
    height: 675px;
  }
}
@media (max-width: 850px) {
  .home-project-slider {
    height: 700px;
  }
}
@media (max-width: 650px) {
  .home-project-slider {
    height: 900px;
  }
}
.home-project-slider .container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 1fr 4fr 1fr;
  grid-template-areas: "box1 box1 box2 box2 box3 box3 box4 box4" "left left left image image image image image" "box7 box7 box8 box8 box9 box9 box10 box10";
}
.home-project-slider .container .empty-box {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (max-width: 1100px) {
  .home-project-slider .container {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 100px 1fr 100px;
    grid-template-areas: "box1 box1 box2 box2 box4 box4" "left left left image image image" "box7 box7 box8 box8 box10 box10";
  }
  .home-project-slider .container .emoty-box3,
.home-project-slider .container .emoty-box9 {
    display: none;
  }
}
@media (max-width: 850px) {
  .home-project-slider .container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "box1 box1 box2 box2" "left left image image" "box7 box7 box10 box10";
  }
  .home-project-slider .container .empty-box4,
.home-project-slider .container .empty-box8 {
    display: none;
  }
  .home-project-slider .container .empty-box10 {
    border-left: 0px solid black !important;
  }
}
@media (max-width: 650px) {
  .home-project-slider .container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 50px 1fr 1fr 50px;
    grid-template-areas: "box1 box1" "left left" "image image" "box7 box7";
  }
  .home-project-slider .container .empty-box2,
.home-project-slider .container .empty-box10 {
    display: none;
  }
  .home-project-slider .container .empty-box1,
.home-project-slider .container .empty-box7,
.home-project-slider .container .title,
.home-project-slider .container .description-box,
.home-project-slider .container .next {
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
.home-project-slider .container .empty-box1 {
  grid-area: box1;
}
.home-project-slider .container .empty-box2 {
  grid-area: box2;
}
.home-project-slider .container .empty-box3 {
  grid-area: box3;
}
.home-project-slider .container .empty-box4 {
  grid-area: box4;
}
.home-project-slider .container .empty-box5 {
  grid-area: box5;
}
.home-project-slider .container .empty-box6 {
  grid-area: box6;
}
.home-project-slider .container .empty-box7 {
  grid-area: box7;
}
.home-project-slider .container .empty-box8 {
  grid-area: box8;
}
.home-project-slider .container .empty-box9 {
  grid-area: box9;
}
.home-project-slider .container .empty-box10 {
  grid-area: box10;
}
.home-project-slider .container .left {
  grid-area: left;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 3fr 8fr -webkit-max-content;
  grid-template-rows: 3fr 8fr max-content;
  grid-template-areas: "title title box5 " "text text text" "box6 prev next";
}
@media (max-width: 850px) {
  .home-project-slider .container .left {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2.5fr 8fr -webkit-max-content;
    grid-template-rows: 2.5fr 8fr max-content;
    grid-template-areas: "title title" "text text" "prev next";
  }
}
@media (max-width: 650px) {
  .home-project-slider .container .left {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 75px 1fr -webkit-max-content;
    grid-template-rows: 75px 1fr max-content;
    grid-template-areas: "title title" "text text" "prev next";
  }
}
.home-project-slider .container .left .box5,
.home-project-slider .container .left .box6 {
  display: none;
}
.home-project-slider .container .empty-box1 {
  display: flex;
  align-items: flex-end;
}
.home-project-slider .container .title {
  grid-area: title;
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-project-slider .container .title h2 {
  font-size: 4.16666666vw;
  font-weight: 400;
  padding-left: 1.73611111vw;
  margin-top: -1.2vw;
}
@media (min-width: 1700px) {
  .home-project-slider .container .title h2 {
    font-size: 71px;
    margin-top: -25px;
    padding-left: 30px;
  }
}
@media (max-width: 1100px) {
  .home-project-slider .container .title h2 {
    font-size: 54px;
    padding-left: 26px;
    margin-top: -15px;
  }
}
@media (max-width: 650px) {
  .home-project-slider .container .title h2 {
    font-size: 39px;
    margin-top: 0px;
  }
}
.home-project-slider .container .image-box {
  grid-area: image;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
}
.home-project-slider .container .image-box::-webkit-scrollbar {
  display: none;
}
.home-project-slider .container .image-box .image-body {
  height: 100%;
  display: flex;
}
.home-project-slider .container .image-box .image-body .image-wrapper {
  flex: 1 0 100%;
  scroll-snap-align: center;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.home-project-slider .container .empty-box4 {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-project-slider .container .empty-box10 {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-project-slider .container .empty-box5 {
  border-right: 0px;
}
.home-project-slider .container .description-box {
  grid-area: text;
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  border-top: 0.5px solid rgba(31, 31, 31, 0.1);
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-project-slider .container .description-box .text {
  margin-left: 1.73611111vw;
  margin-bottom: 2.77777778vw;
  margin-right: 3.47222222vw;
  margin-top: 1.73611111vw;
}
.home-project-slider .container .description-box .text p {
  height: 80px;
  overflow-y: scroll;
}
.home-project-slider .container .description-box .text p::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1100px) {
  .home-project-slider .container .description-box .text p {
    height: 95px;
  }
}
@media (min-width: 1700px) {
  .home-project-slider .container .description-box .text {
    margin-left: 30px;
    margin-right: 59px;
    margin-top: 30px;
    margin-bottom: 47px;
  }
}
@media (max-width: 1100px) {
  .home-project-slider .container .description-box .text {
    margin-left: 25px;
    margin-right: 50px;
    margin-top: 25px;
    margin-bottom: 50px;
  }
}
@media (max-width: 850px) {
  .home-project-slider .container .description-box .text {
    margin-bottom: 40px;
  }
}
.home-project-slider .container .description-box .text h4 {
  font-size: 1.6vw;
  font-weight: 300;
  margin-bottom: 0.833333333vw;
}
@media (min-width: 1700px) {
  .home-project-slider .container .description-box .text h4 {
    font-size: 22px;
    margin-bottom: 14px;
  }
}
@media (max-width: 1100px) {
  .home-project-slider .container .description-box .text h4 {
    font-size: 19px;
    margin-bottom: 20px;
  }
}
.home-project-slider .container .description-box .text p {
  font-weight: 300;
  line-height: 19px;
}
.home-project-slider .container .description-box .button {
  background-color: #1f1f1f;
  color: #ffffff;
}
.home-project-slider .container .prev {
  grid-area: prev;
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-project-slider .container .next {
  grid-area: next;
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-project-slider .container .nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
  position: relative;
  width: 100%;
  cursor: pointer;
}
.home-project-slider .container .nav-button img {
  width: auto;
  height: 2.43055555vw;
}
@media (min-width: 1700px) {
  .home-project-slider .container .nav-button img {
    height: 41px;
  }
}
@media (max-width: 1100px) {
  .home-project-slider .container .nav-button img {
    height: 35px;
  }
}
.home-project-slider .container .nav-button:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.home-counter {
  background-color: #1f1f1f;
  color: #ffffff;
}
.home-counter .container {
  height: 35.4166666vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2.08333333vw;
  grid-template-areas: "box1 box1 box2 empty" "box3 box4 box5 empty";
}
@media (min-width: 1700px) {
  .home-counter .container {
    height: 602px;
    grid-template-columns: 1fr 1fr 1fr 35px;
  }
}
@media (max-width: 1100px) {
  .home-counter .container {
    grid-template-columns: 1fr 1fr 1fr;
    height: 36.3636364vw;
  }
  .home-counter .container .empty {
    display: none;
  }
}
@media (max-width: 850px) {
  .home-counter .container {
    grid-template-areas: "box1 box1 box1" "box3 box4 box5";
  }
  .home-counter .container .box2 {
    display: none !important;
  }
}
@media (max-width: 1100px) {
  .home-counter .container {
    height: 40.3636364vw;
  }
}
@media (max-width: 650px) {
  .home-counter .container {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    grid-template-columns: 1fr;
    grid-template-areas: "box1" "box2" "box3" "box4" "box5";
    grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-rows: max-content max-content max-content max-content max-content;
  }
  .home-counter .container .box2 {
    display: flex !important;
  }
}
.home-counter .container .empty {
  grid-area: empty;
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
}
.home-counter .container .box1 {
  display: flex;
  align-items: flex-end;
  padding: 1.80555555vw;
  grid-area: box1;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 1700px) {
  .home-counter .container .box1 {
    padding: 31px;
  }
}
.home-counter .container .box1 h5 {
  font-size: 4.16666666vw;
  font-weight: 400;
}
@media (min-width: 1700px) {
  .home-counter .container .box1 h5 {
    font-size: 71px;
  }
}
@media (max-width: 850px) {
  .home-counter .container .box1 h5 {
    font-size: 54px;
  }
}
@media (max-width: 730px) {
  .home-counter .container .box1 h5 {
    font-size: 48px;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box1 h5 {
    font-size: 39px;
    padding-right: 20px;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box1 {
    border-bottom: 0px;
    padding-top: 40px;
    padding-left: 20px;
  }
}
.home-counter .container .box2 {
  display: flex;
  align-items: flex-end;
  padding: 1.80555555vw;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  grid-area: box2;
}
@media (min-width: 1700px) {
  .home-counter .container .box2 {
    padding: 31px;
  }
}
@media (max-width: 1100px) {
  .home-counter .container .box2 {
    font-size: 14px;
    line-height: 19px;
  }
}
.home-counter .container .box2 p {
  width: 17.5vw;
}
@media (min-width: 1700px) {
  .home-counter .container .box2 p {
    width: 298px;
  }
}
@media (max-width: 1100px) {
  .home-counter .container .box2 p {
    width: 20vw;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box2 p {
    width: 100%;
    padding-right: 50px;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box2 {
    align-items: flex-start;
    border-left: 0.5px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 40px !important;
    padding-left: 20px;
  }
}
.home-counter .container .box-label {
  width: 70%;
}
@media (max-width: 720px) {
  .home-counter .container .box-label {
    width: 80%;
  }
}
.home-counter .container .box3 {
  grid-area: box3;
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  padding-top: 4.16666666vw;
  padding-left: 4.16666666vw;
}
@media (min-width: 1700px) {
  .home-counter .container .box3 {
    padding-top: 71px;
    padding-left: 71px;
  }
}
@media (max-width: 1100px) {
  .home-counter .container .box3 {
    padding-top: 2.1666666666vw;
    padding-left: 3.1666666667vw;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box3 {
    padding-top: 40px;
    padding-bottom: 40px !important;
    padding-left: 20px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
    border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
.home-counter .container .box3 p {
  font-weight: 300;
}
.home-counter .container .box3 .num {
  font-size: 7.5vw;
  font-weight: 400;
  line-height: 9.16666666vw;
  display: flex;
  align-items: center;
}
@media (min-width: 1700px) {
  .home-counter .container .box3 .num {
    font-size: 128px;
    line-height: 156px;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box3 .num {
    font-size: 80px;
    line-height: 98px;
  }
}
.home-counter .container .box3 .num p {
  line-height: 100%;
}
.home-counter .container .box4 {
  grid-area: box4;
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  padding-top: 4.16666666vw;
  padding-left: 4.16666666vw;
}
@media (min-width: 1700px) {
  .home-counter .container .box4 {
    padding-top: 71px;
    padding-left: 71px;
  }
}
@media (max-width: 1100px) {
  .home-counter .container .box4 {
    padding-top: 2.1666666666vw;
    padding-left: 3.1666666667vw;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box4 {
    padding-top: 40px;
    padding-bottom: 40px !important;
    padding-left: 20px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
    border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
.home-counter .container .box4 p {
  font-weight: 300;
}
.home-counter .container .box4 .num {
  font-size: 7.5vw;
  font-weight: 400;
  line-height: 9.16666666vw;
  display: flex;
  align-items: center;
}
@media (min-width: 1700px) {
  .home-counter .container .box4 .num {
    font-size: 128px;
    line-height: 156px;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box4 .num {
    font-size: 80px;
    line-height: 98px;
  }
}
.home-counter .container .box4 .num p {
  line-height: 100%;
}
.home-counter .container .box5 {
  grid-area: box5;
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  padding-top: 4.16666666vw;
  padding-left: 4.16666666vw;
}
@media (min-width: 1700px) {
  .home-counter .container .box5 {
    padding-top: 71px;
    padding-left: 71px;
  }
}
@media (max-width: 1100px) {
  .home-counter .container .box5 {
    padding-top: 2.1666666666vw;
    padding-left: 3.1666666667vw;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box5 {
    padding-top: 40px;
    padding-bottom: 40px !important;
    padding-left: 20px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
    border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
.home-counter .container .box5 p {
  font-weight: 300;
}
.home-counter .container .box5 .num {
  font-size: 7.5vw;
  font-weight: 400;
  line-height: 9.16666666vw;
  display: flex;
  align-items: center;
}
@media (min-width: 1700px) {
  .home-counter .container .box5 .num {
    font-size: 128px;
    line-height: 156px;
  }
}
@media (max-width: 650px) {
  .home-counter .container .box5 .num {
    font-size: 80px;
    line-height: 98px;
  }
}
.home-counter .container .box5 .num p {
  line-height: 100%;
}
.home-counter .container .box3 {
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 1100px) {
  .home-counter .container .box3 {
    padding-bottom: 2.1666666666vw;
  }
}

.home-image-area {
  background-color: #ffffff;
}
.home-image-area .container {
  display: grid;
  grid-template-columns: 13fr 13fr 14fr;
  grid-template-rows: 4fr 14fr 4fr;
  height: 45.8333333vw;
  grid-template-areas: ". . ." "image image image" ". . .";
}
@media (min-width: 1700px) {
  .home-image-area .container {
    height: 779px;
  }
}
@media (max-width: 1100px) {
  .home-image-area .container {
    height: 600px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 850px) {
  .home-image-area .container {
    grid-template-columns: 1fr 1fr;
    height: 560px;
    grid-template-areas: ". . " "image image" ". .";
  }
}
@media (max-width: 650px) {
  .home-image-area .container {
    grid-template-columns: 1fr;
    height: 575px;
    grid-template-rows: 1fr 400px 1fr;
    grid-template-areas: "." "image" ".";
  }
  .home-image-area .container .box2 {
    border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
.home-image-area .container .box1 {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-image-area .container .box4 {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-image-area .container .box1 {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-image-area .container .box2 {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-image-area .container .box3 {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-image-area .container .box4 {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-image-area .container .box5 {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-image-area .container .box6 {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.home-image-area .image-box {
  grid-area: image;
  font-size: 1.38888889vw;
  line-height: 1.875vw;
}
@media (min-width: 1700px) {
  .home-image-area .image-box {
    grid-template-columns: 1fr 1fr 1fr 35px;
    font-size: 24px;
    line-height: 32px;
  }
}
@media (max-width: 1100px) {
  .home-image-area .image-box {
    grid-template-columns: 1fr;
    font-size: 20px;
    line-height: 32px;
  }
}
@media (max-width: 850px) {
  .home-image-area .image-box {
    font-size: 18px;
    line-height: 24px;
  }
}
.home-image-area .image-box .image {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2.08333333vw;
  background-image: url(../assets/image/image-area.svg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  font-size: 1.38888889vw;
  line-height: 1.875vw;
}
@media (min-width: 1700px) {
  .home-image-area .image-box .image {
    grid-template-columns: 1fr 1fr 1fr 35px;
    font-size: 24px;
    line-height: 32px;
  }
}
@media (max-width: 1100px) {
  .home-image-area .image-box .image {
    grid-template-columns: 1fr;
    font-size: 20px;
    line-height: 32px;
  }
}
@media (max-width: 850px) {
  .home-image-area .image-box .image {
    font-size: 18px;
    line-height: 24px;
    background-attachment: initial;
  }
}
.home-image-area .image-box .image p {
  margin-top: 2.77777778vw;
  margin-left: 2.77777778vw;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: 80%;
  display: flex;
  justify-content: center;
}
@media (min-width: 1700px) {
  .home-image-area .image-box .image p {
    margin-top: 47px;
    margin-left: 47px;
  }
}
@media (max-width: 1100px) {
  .home-image-area .image-box .image p {
    display: inline;
    width: 30%;
    margin-top: 40px;
    margin-left: 40px;
  }
}
@media (max-width: 850px) {
  .home-image-area .image-box .image p {
    width: 50%;
    margin-top: 25px;
    margin-left: 20px;
  }
}

footer {
  background-color: #1f1f1f;
}
footer .created2 {
  display: none;
}
@media (max-width: 651px) {
  footer .created2 {
    display: flex;
  }
  footer .created2 .name2 {
    margin-left: 8px;
  }
}
footer .container {
  height: 33.3333333vw;
  display: grid;
  grid-template-columns: 13fr 13fr 14fr;
}
@media (min-width: 1700px) {
  footer .container {
    height: 567px;
  }
}
@media (max-width: 1100px) {
  footer .container {
    height: 480px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 850px) {
  footer .container {
    height: 380px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left right";
  }
}
@media (max-width: 650px) {
  footer .container {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    grid-template-columns: 1fr;
    grid-template-areas: "left" "right";
  }
}
footer .container .left,
footer .container .middle {
  padding-top: 2.77777778vw;
  padding-left: 3.47222222vw;
}
@media (min-width: 1700px) {
  footer .container .left,
footer .container .middle {
    padding-top: 47px;
    padding-left: 59px;
  }
}
@media (max-width: 1100px) {
  footer .container .left,
footer .container .middle {
    padding-top: 40px;
    padding-left: 40px;
  }
}
footer .container .left {
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2.08333333vw;
}
@media (max-width: 1100px) {
  footer .container .left {
    padding-bottom: 30px;
  }
}
@media (max-width: 650px) {
  footer .container .left {
    padding-left: 30px;
  }
}
footer .container .left img {
  height: 5.13888889vw;
  width: auto;
}
@media (min-width: 1700px) {
  footer .container .left img {
    height: 83px;
    width: auto;
  }
}
@media (max-width: 1100px) {
  footer .container .left img {
    height: 63px;
  }
}
@media (min-width: 1700px) {
  footer .container .left {
    padding-bottom: 35px;
  }
}
footer .container .left .created {
  display: flex;
}
footer .container .left .created .opacity {
  margin-right: 0.555555555vw;
}
@media (min-width: 1700px) {
  footer .container .left .created .opacity {
    margin-right: 9px;
  }
}
@media (max-width: 1100px) {
  footer .container .left .created .opacity {
    margin-right: 8px;
  }
}
@media (max-width: 650px) {
  footer .container .left .created {
    display: none;
  }
}
footer .container .left .logo {
  margin-bottom: 2.77777778vw;
}
@media (min-width: 1700px) {
  footer .container .left .logo {
    margin-bottom: 47px;
  }
}
@media (max-width: 1100px) {
  footer .container .left .logo {
    margin-bottom: 40px;
  }
}
@media (max-width: 650px) {
  footer .container .left .logo {
    display: none;
  }
}
footer .container .left .location {
  display: flex;
  font-weight: 300;
}
footer .container .left .location img {
  margin-left: 1.04166667vw;
  width: auto;
  height: 2.77777778vw;
}
@media (min-width: 1700px) {
  footer .container .left .location img {
    margin-left: 18px;
    height: 47px;
  }
}
@media (max-width: 1100px) {
  footer .container .left .location img {
    height: 40px;
    margin-left: 16px;
  }
}
footer .container .middle {
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
}
footer .container .middle p {
  font-weight: 300;
  margin-bottom: 1.04166667vw;
}
@media (min-width: 1700px) {
  footer .container .middle p {
    margin-bottom: 18px;
  }
}
@media (max-width: 1100px) {
  footer .container .middle p {
    margin-bottom: 16px;
  }
}
@media (max-width: 850px) {
  footer .container .middle {
    display: none !important;
  }
}
footer .container .right {
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  display: grid;
  grid-template-rows: 1fr 1fr 1.26666667fr;
}
@media (max-width: 650px) {
  footer .container .right {
    border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  }
}
footer .container .right .box1,
footer .container .right .box2 {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  padding-left: 2.77777778vw;
}
@media (min-width: 1700px) {
  footer .container .right .box1,
footer .container .right .box2 {
    padding-left: 47px;
  }
}
@media (max-width: 1100px) {
  footer .container .right .box1,
footer .container .right .box2 {
    padding-left: 40px;
  }
}
@media (max-width: 650px) {
  footer .container .right .box1,
footer .container .right .box2 {
    border-bottom: 0px;
  }
}
@media (max-width: 650px) {
  footer .container .right .box1,
footer .container .right .box2 {
    padding-left: 30px;
  }
}
footer .container .right .box1 .opacity,
footer .container .right .box2 .opacity {
  margin-bottom: 0.833333333vw;
}
@media (min-width: 1700px) {
  footer .container .right .box1 .opacity,
footer .container .right .box2 .opacity {
    margin-bottom: 6px;
  }
}
@media (max-width: 1100px) {
  footer .container .right .box1 .opacity,
footer .container .right .box2 .opacity {
    margin-bottom: 12px;
  }
}
@media (max-width: 1100px) {
  footer .container .right .box1 .opacity,
footer .container .right .box2 .opacity {
    margin-bottom: 5px;
  }
}
@media (max-width: 650px) {
  footer .container .right .box1 {
    margin-bottom: 30px;
  }
  footer .container .right .box2 {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 43px;
  }
}
footer .container .right .box3 {
  padding-left: 2.77777778vw;
  display: flex;
  align-items: flex-end;
  opacity: 1;
  padding-bottom: 2.08333333vw;
}
@media (min-width: 1700px) {
  footer .container .right .box3 {
    padding-left: 47px;
    padding-bottom: 35px;
  }
}
@media (max-width: 1100px) {
  footer .container .right .box3 {
    padding-left: 40px;
    padding-bottom: 30px;
  }
}
@media (max-width: 650px) {
  footer .container .right .box3 {
    padding-left: 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
}
footer .container .right .box2,
footer .container .right .box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
footer .container .opacity {
  opacity: 0.3;
}

.project-page {
  background-color: #ffffff;
}
.project-page .project-container {
  color: #1f1f1f;
}
.project-page .project-container .title {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 18.0555556vw;
}
@media (min-width: 1700px) {
  .project-page .project-container .title {
    grid-template-rows: 307px;
  }
}
@media (max-width: 1100px) {
  .project-page .project-container .title {
    grid-template-columns: 1fr;
    grid-template-rows: 240px;
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
    border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
@media (max-width: 850px) {
  .project-page .project-container .title {
    grid-template-rows: 180px;
  }
}
@media (max-width: 650px) {
  .project-page .project-container .title {
    grid-template-rows: 120px;
  }
}
.project-page .project-container .title .first-div {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (max-width: 1100px) {
  .project-page .project-container .title .first-div {
    display: none;
  }
}
.project-page .project-container .title div:nth-child(3) {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (max-width: 1100px) {
  .project-page .project-container .title div:nth-child(3) {
    display: none;
  }
}
.project-page .project-container .title h1 {
  font-size: 4.16666667vw;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  height: 100%;
}
@media (max-width: 850px) {
  .project-page .project-container .title h1 {
    border-top: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
@media (max-width: 650px) {
  .project-page .project-container .title h1 {
    border-top: 0px;
  }
}
.project-page .project-container .title h1 .title-wrapper {
  width: 100%;
  text-align: center;
  border: 0px;
  display: initial;
}
.project-page .project-container .title h1 .title-wrapper div {
  border: 0px;
}
@media (min-width: 1700px) {
  .project-page .project-container .title h1 {
    font-size: 71px;
  }
}
@media (max-width: 1100px) {
  .project-page .project-container .title h1 {
    font-size: 54px;
  }
}
@media (max-width: 850px) {
  .project-page .project-container .title h1 {
    font-size: 50px;
  }
}
@media (max-width: 650px) {
  .project-page .project-container .title h1 {
    font-size: 39px;
  }
}

.project-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 650px) {
  .project-card {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
.project-card .text {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  height: 26.0416667vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.77777778vw;
}
@media (min-width: 1700px) {
  .project-card .text {
    height: 443px;
    padding: 47px;
  }
}
@media (max-width: 1100px) {
  .project-card .text {
    height: 375px;
    padding: 40px 30px;
  }
}
@media (max-width: 850px) {
  .project-card .text {
    height: 315px;
    padding: 30px;
  }
}
@media (max-width: 650px) {
  .project-card .text {
    height: 210px;
    padding: 20px;
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
    border-left: 0.5px solid rgba(31, 31, 31, 0.1) !important;
    justify-content: flex-end;
  }
}
.project-card .text h2 {
  font-size: 3.33333333vw;
  font-weight: 400;
}
@media (min-width: 1700px) {
  .project-card .text h2 {
    font-size: 57px;
  }
}
@media (max-width: 1100px) {
  .project-card .text h2 {
    font-size: 38px;
  }
}
@media (max-width: 850px) {
  .project-card .text h2 {
    font-size: 34px;
  }
}
@media (max-width: 650px) {
  .project-card .text h2 {
    font-size: 34px;
    margin-bottom: 30px;
  }
}
.project-card .text .bottom {
  overflow: hidden;
}
.project-card .text .bottom .bottom-box {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  width: 100%;
  transform: translateY(100%);
}
@media (max-width: 850px) {
  .project-card .text .bottom .bottom-box {
    flex-direction: column;
  }
  .project-card .text .bottom .bottom-box .location {
    margin-top: 25px;
  }
}
@media (max-width: 650px) {
  .project-card .text .bottom .bottom-box {
    flex-direction: row-reverse;
  }
  .project-card .text .bottom .bottom-box .location {
    margin-top: 0px;
  }
}
.project-card .text .bottom .bottom-box .location {
  display: flex;
  align-items: center;
}
.project-card .text .bottom .bottom-box .location img {
  margin-right: 1.041vw;
}
@media (min-width: 1700px) {
  .project-card .text .bottom .bottom-box .location img {
    margin-right: 18px;
  }
}
@media (max-width: 1100px) {
  .project-card .text .bottom .bottom-box .location img {
    margin-right: 15px;
  }
}
.project-card .text .bottom .active-bottom {
  transform: translateY(100%);
  -webkit-animation-name: showText;
          animation-name: showText;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes showText {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes showText {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
.project-card .image {
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
          clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  width: 100%;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: relative;
}
@media (max-width: 650px) {
  .project-card .image {
    height: 375px;
  }
}
.project-card .image .wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  pointer-events: none;
  transition: 0.5s;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5vw;
}
@media (min-width: 1700px) {
  .project-card .image .wrapper {
    font-size: 30px;
  }
}
@media (max-width: 1100px) {
  .project-card .image .wrapper {
    font-size: 22px;
  }
}
@media (max-width: 800px) {
  .project-card .image .wrapper {
    font-size: 18px;
  }
}
.project-card .image:hover .wrapper {
  opacity: 1;
}
.project-card:nth-child(2) .text {
  border-top: 0.5px solid rgba(31, 31, 31, 0.1);
}
.project-card .last {
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
}
.project-card:nth-child(odd) {
  grid-template-areas: "image text";
  flex-direction: row-reverse;
}
@media (max-width: 650px) {
  .project-card:nth-child(odd) {
    grid-template-areas: "text" "image";
    flex-direction: column;
  }
}
.project-card:nth-child(odd) .text {
  grid-area: text;
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-left: 0px;
}

.load-more {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  height: 26.0416667vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1700px) {
  .load-more {
    height: 443px;
  }
}
@media (max-width: 1100px) {
  .load-more {
    height: 250px;
  }
}
@media (max-width: 850px) {
  .load-more {
    height: 200px;
  }
}
@media (max-width: 650px) {
  .load-more {
    height: 175px;
  }
}
.load-more p {
  font-size: 4.16666667vw;
  font-weight: 400;
  line-height: 5.625vw;
  border-bottom: 2px solid #1f1f1f;
}
@media (min-width: 1700px) {
  .load-more p {
    font-size: 71px;
    line-height: 96px;
  }
}
@media (max-width: 1100px) {
  .load-more p {
    font-size: 54px;
    line-height: 72px;
  }
}
@media (max-width: 850px) {
  .load-more p {
    font-size: 50px;
    line-height: 67px;
  }
}
@media (max-width: 650px) {
  .load-more p {
    font-size: 32px;
    line-height: 42px;
  }
}

.contact-page {
  color: #1f1f1f;
}
.contact-page .title-section {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
@media (max-width: 1100px) {
  .contact-page .title-section {
    grid-template-columns: 2fr 1fr;
  }
}
@media (max-width: 850px) {
  .contact-page .title-section {
    grid-template-columns: 1.7fr 1fr;
    border-top: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
@media (max-width: 650px) {
  .contact-page .title-section {
    grid-template-columns: 1fr;
    border-top: 0px;
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
.contact-page .title-section .title {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  padding-left: 8.33333333vw;
  padding-top: 6.94444444vw;
  padding-bottom: 6.94444444vw;
}
.contact-page .title-section .title h1 {
  font-size: 4.16666667vw;
  font-weight: 400;
}
.contact-page .title-section .title .description {
  font-size: 1.38888889vw;
  font-weight: 400;
}
@media (min-width: 1700px) {
  .contact-page .title-section .title {
    padding-left: 142px;
    padding-top: 118px;
    padding-bottom: 118px;
  }
  .contact-page .title-section .title h1 {
    font-size: 71px;
  }
  .contact-page .title-section .title .description {
    font-size: 24px;
  }
}
@media (max-width: 1100px) {
  .contact-page .title-section .title {
    padding-left: 80px;
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .contact-page .title-section .title h1 {
    font-size: 54px;
  }
  .contact-page .title-section .title .description {
    font-size: 18px;
  }
}
@media (max-width: 850px) {
  .contact-page .title-section .title {
    padding-left: 70px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .contact-page .title-section .title h1 {
    font-size: 50px;
  }
}
@media (max-width: 650px) {
  .contact-page .title-section .title {
    padding-left: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .contact-page .title-section .title h1 {
    font-size: 39px;
  }
  .contact-page .title-section .title .description {
    font-size: 16px;
  }
}
.contact-page .title-section .box2 {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 850px) {
  .contact-page .title-section .box2 {
    border-left: 0px;
  }
}
@media (max-width: 650px) {
  .contact-page .title-section .box2 {
    display: none;
  }
}

.contact-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 650px) {
  .contact-info {
    grid-template-columns: 1fr;
  }
}
.contact-info .number,
.contact-info .email {
  border-top: 0.5px solid rgba(31, 31, 31, 0.1);
  padding: 4.16666667vw 0 4.16666667vw 4.86111111vw;
}
@media (min-width: 1700px) {
  .contact-info .number,
.contact-info .email {
    padding: 71px 0 71px 83px;
  }
}
@media (max-width: 1100px) {
  .contact-info .number,
.contact-info .email {
    padding: 50px 0 50px 50px;
  }
}
@media (max-width: 850px) {
  .contact-info .number,
.contact-info .email {
    padding: 30px 0 30px 30px;
  }
}
@media (max-width: 650px) {
  .contact-info .number,
.contact-info .email {
    padding-left: 25px;
  }
}
.contact-info .email {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (max-width: 650px) {
  .contact-info .email {
    border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
.contact-info .number {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.contact-info .value {
  font-size: 2.77777778vw;
  font-weight: 600;
}
@media (min-width: 1700px) {
  .contact-info .value {
    font-size: 47px;
  }
}
@media (max-width: 1100px) {
  .contact-info .value {
    font-size: 34px;
  }
}
@media (max-width: 850px) {
  .contact-info .value {
    font-size: 22px;
  }
}
@media (max-width: 650px) {
  .contact-info .value {
    font-size: 24px;
  }
}
.contact-info .opacity {
  opacity: 0.4;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 20px;
}
@media (min-width: 1700px) {
  .contact-info .opacity {
    margin-bottom: 20px;
  }
}
@media (max-width: 650px) {
  .contact-info .opacity {
    margin-bottom: 15px;
  }
}

.contact-page .image {
  background-attachment: fixed !important;
  height: 31.25vw;
}
@media (min-width: 1700px) {
  .contact-page .image {
    height: 531px;
  }
}
@media (max-width: 1100px) {
  .contact-page .image {
    height: 323px;
  }
}
@media (max-width: 850px) {
  .contact-page .image {
    height: 247px;
    background-attachment: inherit !important;
  }
}
@media (max-width: 650px) {
  .contact-page .image {
    height: 288px;
  }
}

.text-area {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
@media (max-width: 1100px) {
  .text-area {
    grid-template-columns: 1fr;
  }
}
.text-area .text {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
  padding-left: 8.33333333vw;
  padding-top: 6.94444444vw;
  padding-bottom: 6.94444444vw;
}
.text-area .text h4 {
  font-size: 4.16666667vw;
  font-weight: 400;
}
@media (min-width: 1700px) {
  .text-area .text {
    padding-left: 142px;
    padding-top: 118px;
    padding-bottom: 118px;
  }
  .text-area .text h4 {
    font-size: 71px;
  }
}
@media (max-width: 1100px) {
  .text-area .text {
    padding-left: 80px;
    padding-top: 90px;
    padding-bottom: 90px;
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  }
  .text-area .text h4 {
    font-size: 54px;
    line-height: 72px;
  }
}
@media (max-width: 850px) {
  .text-area .text {
    padding-left: 70px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .text-area .text h4 {
    font-size: 50px;
    line-height: 67px;
  }
}
@media (max-width: 650px) {
  .text-area .text {
    padding-left: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .text-area .text h4 {
    font-size: 39px;
    line-height: 42px;
  }
}
.text-area .empty-box {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (max-width: 1100px) {
  .text-area .empty-box {
    display: none;
  }
}

.contact-page .contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: ". . . ." "button button button .";
}
@media (max-width: 1100px) {
  .contact-page .contact-form {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". . ." "button button button" ". . .";
  }
}
@media (max-width: 650px) {
  .contact-page .contact-form {
    grid-template-columns: 1fr;
    grid-template-areas: "." "." "." "button" ".";
  }
}
.contact-page .contact-form input {
  width: 100%;
  height: 4.86111111vw;
  padding-left: 2.08333333vw;
  display: flex;
  align-items: center;
  border: 0px;
  outline: 0px;
  opacity: 0.8;
}
@media (min-width: 1700px) {
  .contact-page .contact-form input {
    height: 83px;
    padding-left: 35px;
  }
}
@media (max-width: 1100px) {
  .contact-page .contact-form input {
    height: 70px;
    padding-left: 30px;
  }
}
@media (max-width: 650px) {
  .contact-page .contact-form input {
    height: 55px;
    padding-left: 25px;
  }
}
@media (max-width: 1100px) {
  .contact-page .contact-form .message {
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
.contact-page .contact-form .name,
.contact-page .contact-form .email {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.contact-page .contact-form .name {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
.contact-page .contact-form .empty-box {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (max-width: 1100px) {
  .contact-page .contact-form .empty-box {
    display: none;
  }
}
@media (max-width: 650px) {
  .contact-page .contact-form .name,
.contact-page .contact-form .email,
.contact-page .contact-form .message {
    border-left: 0.5px solid rgba(31, 31, 31, 0.1);
    border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
.contact-page .contact-form .eb2 {
  border-top: 0.5px solid rgba(31, 31, 31, 0.1);
  height: 12.5vw;
}
@media (min-width: 1700px) {
  .contact-page .contact-form .eb2 {
    height: 213px;
  }
}
@media (max-width: 1100px) {
  .contact-page .contact-form .eb2 {
    display: none;
  }
}
.contact-page .contact-form .button-section {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  width: 100%;
  grid-area: button;
  cursor: pointer;
}
.contact-page .contact-form .button-section .button {
  background-color: #1f1f1f;
  color: #ffffff;
}
@media (max-width: 1100px) {
  .contact-page .contact-form .button-section {
    padding-bottom: 100px;
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
@media (max-width: 850px) {
  .contact-page .contact-form .button-section {
    padding-bottom: 100px;
  }
}
@media (max-width: 650px) {
  .contact-page .contact-form .button-section {
    padding-bottom: 45px;
  }
}

.services-page {
  color: #1f1f1f;
}
.services-page .services-collapse .button-tabs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 5.20833333vw;
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (min-width: 1700px) {
  .services-page .services-collapse .button-tabs {
    grid-template-rows: 89px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-collapse .button-tabs {
    grid-template-rows: 75px;
    font-size: 14px;
  }
}
@media (max-width: 850px) {
  .services-page .services-collapse .button-tabs {
    grid-template-rows: 64px;
  }
}
@media (max-width: 650px) {
  .services-page .services-collapse .button-tabs {
    grid-template-rows: 70px 70px 70px 70px;
    grid-template-columns: 1fr;
  }
}
.services-page .services-collapse .button-tabs .collapse-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 300;
}
@media (max-width: 850px) {
  .services-page .services-collapse .button-tabs .collapse-button {
    text-align: center;
  }
}
@media (max-width: 650px) {
  .services-page .services-collapse .button-tabs .collapse-button {
    border-left: 0.5px solid rgba(31, 31, 31, 0.1);
    border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  }
}
.services-page .services-collapse .button-tabs .collapse-button:nth-child(1) {
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
.services-page .services-collapse .button-tabs .collapse-button:nth-child(4) {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
}
.services-page .services-collapse .button-tabs .active {
  background-color: #1f1f1f;
  color: #ffffff;
}
.services-page .services-collapse .collapse-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 22.7083333vw 11.1805556vw;
  padding-bottom: 5.555555556vw;
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  grid-template-areas: "image top" "image bottom";
}
@media (min-width: 1700px) {
  .services-page .services-collapse .collapse-body {
    grid-template-rows: 386px 190px;
    padding-bottom: 94px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-collapse .collapse-body {
    grid-template-rows: 250px 130px;
    padding-bottom: 60px;
  }
}
@media (max-width: 850px) {
  .services-page .services-collapse .collapse-body {
    grid-template-rows: 190px 110px;
    padding-bottom: 45px;
  }
}
@media (max-width: 700px) {
  .services-page .services-collapse .collapse-body {
    grid-template-columns: 1fr;
    grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-rows: max-content max-content max-content;
    grid-template-areas: "top" "image" "bottom";
    padding-bottom: 0px;
  }
}
.services-page .services-collapse .collapse-body .image {
  grid-area: image;
}
.services-page .services-collapse .collapse-body .image img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.services-page .services-collapse .collapse-body .top {
  grid-area: top;
  padding-top: 5vw;
  margin-left: 5vw;
}
.services-page .services-collapse .collapse-body .top p {
  width: 23.2638889vw;
  font-weight: 300;
}
@media (min-width: 1700px) {
  .services-page .services-collapse .collapse-body .top {
    padding-top: 85px;
    margin-left: 85px;
  }
  .services-page .services-collapse .collapse-body .top p {
    width: 395px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-collapse .collapse-body .top {
    padding-top: 40px;
    margin-left: 60px;
    padding-right: 75px;
  }
  .services-page .services-collapse .collapse-body .top p {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .services-page .services-collapse .collapse-body .top {
    padding-top: 30px;
    margin-left: 35px;
    padding-right: 40px;
  }
}
@media (max-width: 700px) {
  .services-page .services-collapse .collapse-body .top {
    margin-left: 0px;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
  }
}
.services-page .services-collapse .collapse-body .bottom {
  grid-area: bottom;
  border-top: 0.5px solid rgba(31, 31, 31, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 5vw;
}
.services-page .services-collapse .collapse-body .bottom p {
  width: 23.2638889vw;
  font-weight: 300;
}
@media (min-width: 1700px) {
  .services-page .services-collapse .collapse-body .bottom {
    margin-left: 85px;
  }
  .services-page .services-collapse .collapse-body .bottom p {
    width: 395px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-collapse .collapse-body .bottom {
    margin-left: 60px;
    padding-right: 75px;
  }
  .services-page .services-collapse .collapse-body .bottom p {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .services-page .services-collapse .collapse-body .bottom {
    margin-left: 35px;
    padding-right: 40px;
  }
}
@media (max-width: 700px) {
  .services-page .services-collapse .collapse-body .bottom {
    margin-left: 0px;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
  }
}
.services-page .services-collapse .collapse-body .bottom .price {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.59722222vw;
  margin-bottom: 1.21428571;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1700px) {
  .services-page .services-collapse .collapse-body .bottom .price {
    font-size: 27px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-collapse .collapse-body .bottom .price {
    font-size: 20px;
  }
}
@media (max-width: 850px) {
  .services-page .services-collapse .collapse-body .bottom .price {
    font-size: 16px;
  }
}
@media (max-width: 700px) {
  .services-page .services-collapse .collapse-body .bottom .price {
    font-size: 18px;
  }
}
.services-page .services-collapse .collapse-body .bottom .smaller {
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
}
.services-page .services-accordion {
  border-top: 0.5px solid rgba(31, 31, 31, 0.1);
}
.services-page .services-accordion .accordion-text {
  display: grid;
  grid-template-columns: 5fr 1fr;
}
@media (max-width: 850px) {
  .services-page .services-accordion .accordion-text {
    grid-template-columns: 4fr 1fr;
  }
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-text {
    grid-template-columns: 1fr;
  }
}
.services-page .services-accordion .accordion-text h2 {
  font-weight: 400;
  font-size: 4.16666667vw;
  padding-left: 8.33333333vw;
  padding-top: 6.94444444vw;
  padding-bottom: 6.94444444vw;
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (min-width: 1700px) {
  .services-page .services-accordion .accordion-text h2 {
    font-size: 71px;
    padding-left: 142px;
    padding-top: 118px;
    padding-bottom: 118px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-accordion .accordion-text h2 {
    font-size: 54px;
    padding-left: 80px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (max-width: 850px) {
  .services-page .services-accordion .accordion-text h2 {
    font-size: 44px;
    line-height: 59px;
    padding-left: 40px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-text h2 {
    font-size: 32px;
    line-height: 42px;
    padding-left: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.services-page .services-accordion .accordion-text .empty-box {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-text .empty-box {
    border: 0px;
  }
}
.services-page .services-accordion .accordion-box {
  display: grid;
  grid-template-columns: 5fr 1fr;
  overflow: hidden;
  border-bottom: 0.5px solid rgba(31, 31, 31, 0.1);
  transition: 0.5s;
}
@media (max-width: 850px) {
  .services-page .services-accordion .accordion-box {
    grid-template-columns: 4fr 1fr;
  }
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-box {
    grid-template-columns: 4fr 1fr;
  }
}
.services-page .services-accordion .accordion-box .button {
  cursor: pointer;
}
.services-page .services-accordion .accordion-box img {
  height: 2.43055556vw;
  width: 100%;
  transform: rotate(0deg);
  transition: 0.5s;
}
@media (min-width: 1700px) {
  .services-page .services-accordion .accordion-box img {
    height: 41px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-accordion .accordion-box img {
    height: 35px;
  }
}
@media (max-width: 850px) {
  .services-page .services-accordion .accordion-box img {
    height: 25px;
  }
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-box img {
    height: 14px;
  }
}
.services-page .services-accordion .accordion-box .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 13.8888889vw;
}
@media (min-width: 1700px) {
  .services-page .services-accordion .accordion-box .top {
    height: 236px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-accordion .accordion-box .top {
    height: 160px;
  }
}
@media (max-width: 850px) {
  .services-page .services-accordion .accordion-box .top {
    height: 150px;
  }
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-box .top {
    height: 150px;
  }
}
.services-page .services-accordion .accordion-box .body .description {
  width: 100% !important;
  padding-bottom: 35px;
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-box .body .description {
    display: initial !important;
    width: 100% !important;
  }
}
.services-page .services-accordion .accordion-box .text {
  padding-left: 3.47222222vw;
  padding-right: 3.47222222vw;
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (min-width: 1700px) {
  .services-page .services-accordion .accordion-box .text {
    padding-left: 59px;
    padding-right: 59px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-accordion .accordion-box .text {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-box .text {
    padding-left: 25px;
    font-weight: 600;
    font-size: 18px;
    padding-right: 25px;
  }
}
.services-page .services-accordion .accordion-box .text .description {
  margin-top: 0.714285714;
  font-size: 1.38888889vw;
  line-height: 1.73611111vw;
  font-weight: 300;
  width: 31.25vw;
}
@media (min-width: 1700px) {
  .services-page .services-accordion .accordion-box .text .description {
    font-size: 24px;
    line-height: 30px;
    width: 531px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-accordion .accordion-box .text .description {
    font-size: 16px;
    line-height: 20px;
    width: 360px;
  }
}
@media (max-width: 700px) {
  .services-page .services-accordion .accordion-box .text .description {
    margin-top: 5px;
    width: 100% !important;
  }
}
.services-page .services-accordion .accordion-box .button {
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  display: initial;
  height: 100%;
}
.services-page .services-accordion .accordion-box .button .button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13.8888889vw;
}
@media (min-width: 1700px) {
  .services-page .services-accordion .accordion-box .button .button-box {
    height: 236px;
  }
}
@media (max-width: 1100px) {
  .services-page .services-accordion .accordion-box .button .button-box {
    height: 160px;
  }
}
@media (max-width: 850px) {
  .services-page .services-accordion .accordion-box .button .button-box {
    height: 150px;
  }
}
@media (max-width: 650px) {
  .services-page .services-accordion .accordion-box .button .button-box {
    height: 150px;
  }
}

.about-page {
  color: #1f1f1f;
}
.about-page .about-image {
  background-image: url("../assets/image/about.svg");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 36.3194444vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 8.33333333vw;
}
@media (min-width: 1700px) {
  .about-page .about-image {
    height: 617px;
    padding-left: 142px;
  }
}
@media (max-width: 1100px) {
  .about-page .about-image {
    height: 400px;
    padding-left: 80px;
  }
}
@media (max-width: 850px) {
  .about-page .about-image {
    height: 320px;
    padding-left: 70px;
    background-attachment: initial;
  }
}
@media (max-width: 650px) {
  .about-page .about-image {
    height: 390px;
    padding-left: 25px;
  }
}
.about-page .about-image p {
  font-size: 5.55555556vw;
  font-weight: 400;
  line-height: 7.43055556vw;
}
@media (min-width: 1700px) {
  .about-page .about-image p {
    font-size: 94px;
    line-height: 126px;
  }
}
@media (max-width: 1100px) {
  .about-page .about-image p {
    font-size: 60px;
    line-height: 81px;
  }
}
@media (max-width: 850px) {
  .about-page .about-image p {
    font-size: 50px;
    line-height: 67px;
  }
}
@media (max-width: 650px) {
  .about-page .about-image p {
    font-size: 60px;
    line-height: 81px;
  }
}
.about-page .about-text {
  padding-left: 8.33333333vw;
  padding-top: 6.94444444vw;
  padding-bottom: 6.94444444vw;
  border-right: 0.5px solid rgba(31, 31, 31, 0.1);
  border-left: 0.5px solid rgba(31, 31, 31, 0.1);
}
@media (min-width: 1700px) {
  .about-page .about-text {
    padding-left: 142px;
    padding-top: 118px;
    padding-bottom: 118px;
  }
}
@media (max-width: 1100px) {
  .about-page .about-text {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
@media (max-width: 850px) {
  .about-page .about-text {
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 70px;
    padding-top: 70px;
  }
}
@media (max-width: 650px) {
  .about-page .about-text {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 70px;
    padding-top: 70px;
  }
}
.about-page .about-text h2 {
  font-size: 2.77777778vw;
  margin-bottom: 1.73611111vw;
  font-weight: 400;
}
@media (min-width: 1700px) {
  .about-page .about-text h2 {
    font-size: 47px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1100px) {
  .about-page .about-text h2 {
    font-size: 34px;
    line-height: 46px;
  }
}
@media (max-width: 850px) {
  .about-page .about-text h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 650px) {
  .about-page .about-text h2 {
    font-size: 32px;
    line-height: 42px;
  }
}
.about-page .about-text .text-wrapper {
  width: 800px;
  font-size: 20px;
  line-height: 32px;
}
@media (max-width: 1100px) {
  .about-page .about-text .text-wrapper {
    width: 100%;
    font-size: 16px;
    line-height: 26px;
  }
}
.about-page .about-text:nth-child(6) {
  padding-top: 0px;
}

.modal-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-slider .background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 31, 31, 0.93);
}
.modal-slider .slider-body {
  width: 80vw;
}
@media (max-width: 850px) {
  .modal-slider .slider-body {
    width: 91vw;
  }
}
.modal-slider .slider-body .top {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 40px;
  position: relative;
  pointer-events: none;
}
@media (max-width: 850px) {
  .modal-slider .slider-body .top {
    margin-bottom: 20px;
  }
}
.modal-slider .slider-body .top .title {
  text-transform: uppercase;
}
.modal-slider .slider-body .top .close {
  cursor: pointer;
  pointer-events: initial;
}
.modal-slider .slider-body .image-box {
  position: relative;
}
.modal-slider .slider-body .image-box .image-slider {
  height: 80vh;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
@media (max-width: 850px) {
  .modal-slider .slider-body .image-box .image-slider {
    height: 30vh;
    overflow-x: scroll;
  }
  .modal-slider .slider-body .image-box .image-slider::-webkit-scrollbar {
    display: none;
  }
}
.modal-slider .slider-body .image-box .image-slider .image-wrapper {
  flex: 1 0 100%;
  width: 100%;
  height: 100%;
}
.modal-slider .slider-body .image-box .image-slider .image-wrapper img {
  -o-object-fit: cover;
     object-fit: cover;
  scroll-snap-align: center;
  width: 100%;
  height: 100%;
}
.modal-slider .slider-body .image-box .slider-button {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: auto;
}
@media (max-width: 850px) {
  .modal-slider .slider-body .image-box .slider-button {
    top: initial;
    display: initial;
    align-items: initial;
    transform: translateY(100%);
    padding: 25px;
  }
}
.modal-slider .slider-body .image-box .slider-button img {
  pointer-events: initial;
  cursor: pointer;
  height: 35px;
}
@media (max-width: 850px) {
  .modal-slider .slider-body .image-box .slider-button img {
    height: 20px;
  }
}
.modal-slider .slider-body .image-box .prev {
  left: -60px;
}
@media (max-width: 850px) {
  .modal-slider .slider-body .image-box .prev {
    left: -25px;
  }
}
.modal-slider .slider-body .image-box .next {
  right: -60px;
}
@media (max-width: 850px) {
  .modal-slider .slider-body .image-box .next {
    right: -25px;
  }
}